import React from "react";

import { ThemeProvider_workspace$data } from "../components/app/__generated__/ThemeProvider_workspace.graphql";
import { brandedAppWorkspaceKey } from "../utils/mobile";

export const CurrentWorkspaceContext =
  React.createContext<ThemeProvider_workspace$data>(null);

export function useCurrentWorkspace() {
  return React.useContext(CurrentWorkspaceContext);
}

export function useCurrentBrand() {
  const workspace = useCurrentWorkspace();

  // Indicates if the current environment is a branded mobile app.
  const isMobileBrandedApp = Boolean(brandedAppWorkspaceKey);

  // Indicates if the current workspace is branded.
  const isBrandedWorkspace = workspace?.isBrandedApp;

  // Indicates if the current environment is branded, either as a mobile app or a workspace.
  const isBranded = isMobileBrandedApp || isBrandedWorkspace;

  // The name of the brand, using the workspace name if branded; otherwise or if workspace name is empty, defaults to "Stridist".
  const brandName =
    isBrandedWorkspace && workspace.name ? workspace.name : "Stridist";

  return {
    isBrandedApp: isMobileBrandedApp,
    isBrandedWorkspace,
    brandName,
    isBranded,
  };
}
