import clsx from "clsx";
import React, { FormEventHandler } from "react";
import {
  Box,
  BoxProps,
  Typography,
  TextField,
  IconButton,
  Link,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { AuthButton } from "./AuthButton";
import { urlConstants } from "../../constants/urlConstants";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 457,
    textAlign: "center",
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(1.5),
  },

  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3.5),
  },

  password: {
    marginTop: theme.spacing(2),
  },

  passwordInput: {
    paddingRight: 0,
  },

  conditions: {
    margin: theme.spacing(2, "auto", 0),
    width: 274,
    fontSize: 13,
    fontWeight: 500,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    textAlign: "center",
  },
}));

export interface ClientAcceptInviteFormProps extends BoxProps {
  coachName: string;
  email: string;
  password: string;
  passwordError: string;
  onPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: FormEventHandler<HTMLDivElement>;
  submitDisabled?: boolean;
}

export function ClientAcceptInviteForm(props: ClientAcceptInviteFormProps) {
  const {
    className,
    coachName,
    email,
    password,
    passwordError,
    onPasswordChange,
    onSubmit,
    submitDisabled = false,
    ...other
  } = props;
  const s = useStyles();
  const { brandName } = useCurrentBrand();

  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const VisibilityIcon = passwordVisible ? VisibilityOff : Visibility;

  const handlePasswordVisibilityToggle = React.useCallback(() => {
    setPasswordVisible((value) => !value);
  }, []);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Typography className={s.title} variant="h1">
        Accept {coachName}'s Invite
      </Typography>

      <Typography className={s.subtitle} variant="h5">
        Create a free {brandName} account to join your coach.
      </Typography>

      <Box component={"form"} onSubmit={onSubmit}>
        <TextField
          variant="outlined"
          name="email"
          type="email"
          label="Email address"
          placeholder="Email address"
          value={email}
          disabled={true}
          fullWidth
        />

        <TextField
          className={s.password}
          variant="outlined"
          name="password"
          type={passwordVisible ? "text" : "password"}
          label="Password"
          placeholder="Password"
          value={password}
          onChange={onPasswordChange}
          error={Boolean(passwordError)}
          helperText={
            passwordError ||
            "Your password should be at least 8 characters long."
          }
          fullWidth
          InputProps={{
            className: s.passwordInput,
            endAdornment: (
              <IconButton onClick={handlePasswordVisibilityToggle} size="large">
                <VisibilityIcon />
              </IconButton>
            ),
          }}
        />

        <AuthButton
          type="submit"
          children="Sign Up Free"
          disabled={submitDisabled}
        />
      </Box>

      <Typography className={s.conditions}>
        By signing up, you agree to our{" "}
        <Link
          href={urlConstants.terms}
          target="_blank"
          rel="noopener noreferrer"
        >
          terms of service
        </Link>{" "}
        and{" "}
        <Link
          href={urlConstants.privacy}
          target="_blank"
          rel="noopener noreferrer"
        >
          privacy policy
        </Link>
        .
      </Typography>
    </Box>
  );
}
