/**
 * @generated SignedSource<<0d67fcf34966ed1f8194cae50d29c18b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateThemeInput = {
  clientMutationId?: string | null | undefined;
  primaryColor: string;
};
export type UpdateThemeMutation$variables = {
  input: UpdateThemeInput;
};
export type UpdateThemeMutation$data = {
  readonly updateTheme: {
    readonly workspace: {
      readonly " $fragmentSpreads": FragmentRefs<"ThemeProvider_workspace">;
    };
  } | null | undefined;
};
export type UpdateThemeMutation = {
  response: UpdateThemeMutation$data;
  variables: UpdateThemeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateThemeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateThemePayload",
        "kind": "LinkedField",
        "name": "updateTheme",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Workspace",
            "kind": "LinkedField",
            "name": "workspace",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ThemeProvider_workspace"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateThemeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateThemePayload",
        "kind": "LinkedField",
        "name": "updateTheme",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Workspace",
            "kind": "LinkedField",
            "name": "workspace",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "primaryColor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "logoURL",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mealLogging",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isBrandedApp",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bdcfcf29d4fb9e97c6e8b90bfa6b7f0e",
    "id": null,
    "metadata": {},
    "name": "UpdateThemeMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateThemeMutation(\n  $input: UpdateThemeInput!\n) {\n  updateTheme(input: $input) {\n    workspace {\n      ...ThemeProvider_workspace\n      id\n    }\n  }\n}\n\nfragment ThemeProvider_workspace on Workspace {\n  primaryColor\n  name\n  logoURL\n  message\n  slug\n  mealLogging\n  isBrandedApp\n}\n"
  }
};
})();

(node as any).hash = "b0c997f87e46e5ded94baf235d8ddfb1";

export default node;
