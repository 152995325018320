import React from "react";
import clsx from "clsx";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useMutation } from "react-relay/hooks";

import { useAnalytics } from "../../hooks/useAnalytics";
import { Link } from "../link/Link";
import { AcceptClientInviteInvalidReason } from "../../constants";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { colorSystem } from "../../theme";
import { AppLogo } from "../app/AppLogo";

import { ClientAcceptInviteInvalidSignOutMutation } from "./__generated__/ClientAcceptInviteInvalidSignOutMutation.graphql";
import { AuthButton } from "./AuthButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(10, 3),
    backgroundColor: colorSystem.white2,
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.common.black,
    margin: theme.spacing(4, 0, 1.5),
    textAlign: "center",
  },

  text: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(1),
    textAlign: "center",
  },

  button: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1.75, 10),
  },
}));

const signOutMutation = graphql`
  mutation ClientAcceptInviteInvalidSignOutMutation {
    signOut
  }
`;

export interface ClientAcceptInviteInvalidProps extends BoxProps {
  reason: AcceptClientInviteInvalidReason;
}

export function ClientAcceptInviteInvalid(
  props: ClientAcceptInviteInvalidProps,
) {
  const { className, reason, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const { brandName } = useCurrentBrand();
  const [signOut] =
    useMutation<ClientAcceptInviteInvalidSignOutMutation>(signOutMutation);
  const [trackEvent] = useAnalytics();
  const location = useLocation();
  const onError = useGenericErrorHandler();

  const handleLogOut = React.useCallback(() => {
    const returnUrl = location.pathname + location.search;

    signOut({
      variables: {},
      onCompleted() {
        trackEvent("Logout");
        navigate(returnUrl);
      },
      updater: (store) => {
        (store as any).invalidateStore();
      },
      onError,
    });
  }, [signOut, onError, trackEvent]);

  const content = React.useMemo(() => {
    switch (reason) {
      case AcceptClientInviteInvalidReason.ANOTHER_USER_LOGGED:
        return (
          <>
            <Typography className={s.text}>
              You’ll need to log out of {brandName} before you can accept an
              invitation.
            </Typography>

            <AuthButton
              className={s.button}
              onClick={handleLogOut}
              children="Log Out"
              fullWidth={false}
            />
          </>
        );
      case AcceptClientInviteInvalidReason.INVITATION_REVOKED:
        return (
          <>
            <Typography className={s.text}>
              Sorry, but it looks like that invitation has been revoked.
            </Typography>
            <Typography className={s.text}>
              Contact your coach if you think this was a mistake.
            </Typography>
          </>
        );
      case AcceptClientInviteInvalidReason.INVITATION_ACCEPTED:
      default:
        return (
          <>
            <Typography className={s.text}>
              Sorry, but it looks like that invitation has already been
              accepted.
            </Typography>
            <Typography className={s.text}>
              Did you mean to <Link href="/login">sign in</Link> instead?
            </Typography>
          </>
        );
    }
  }, [handleLogOut, reason, s.button, s.text]);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <AppLogo main full width={160} height={37} />

      <Typography className={s.title} variant="h1">
        Oops, that didn’t work
      </Typography>

      {content}
    </Box>
  );
}
