import clsx from "clsx";
import React, { FormEventHandler } from "react";
import {
  Box,
  BoxProps,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { AppLogo } from "../app/AppLogo";
import { useMediaMobile } from "../../hooks/useMediaMobile";

import { AuthButton } from "./AuthButton";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 457,
  },

  logo: {
    marginBottom: theme.spacing(8),
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
  },

  subtitle: {
    marginTop: theme.spacing(0.5),
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },

  name: {
    marginTop: theme.spacing(3),
  },

  password: {
    marginTop: theme.spacing(2),
  },

  passwordInput: {
    paddingRight: 0,
  },
}));

export type SignUpValues = {
  name: string;
  password: string;
};

export interface SignUpSetUpFormProps extends BoxProps {
  values: SignUpValues;
  errors: SignUpValues;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: FormEventHandler<HTMLDivElement>;
  submitDisabled?: boolean;
}

export function SignUpSetUpForm(props: SignUpSetUpFormProps) {
  const {
    className,
    values,
    errors,
    onChange,
    onSubmit,
    submitDisabled = false,
    ...other
  } = props;
  const s = useStyles();
  const { brandName } = useCurrentBrand();
  const isMobile = useMediaMobile();
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const VisibilityIcon = passwordVisible ? VisibilityOff : Visibility;

  const handlePasswordVisibilityToggle = React.useCallback(() => {
    setPasswordVisible((value) => !value);
  }, []);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {isMobile && (
        <AppLogo className={s.logo} main full width={160} height={37} />
      )}

      <Typography className={s.title} variant="h1">
        Welcome to {brandName}
      </Typography>
      <Typography className={s.subtitle} variant="h5">
        One more step to set up your profile.
      </Typography>

      <Box component={"form"} onSubmit={onSubmit}>
        <TextField
          className={s.name}
          variant="outlined"
          name="name"
          type="text"
          label="Full name"
          placeholder="Full name"
          value={values.name}
          onChange={onChange}
          error={Boolean(errors.name)}
          helperText={errors.name}
          fullWidth
        />

        <TextField
          className={s.password}
          variant="outlined"
          name="password"
          type={passwordVisible ? "text" : "password"}
          label="Password"
          placeholder="Password"
          value={values.password}
          onChange={onChange}
          error={Boolean(errors.password)}
          helperText={
            errors.password ||
            "Your password should be at least 8 characters long."
          }
          fullWidth
          InputProps={{
            className: s.passwordInput,
            endAdornment: (
              <IconButton onClick={handlePasswordVisibilityToggle} size="large">
                <VisibilityIcon />
              </IconButton>
            ),
          }}
        />

        <AuthButton
          type="submit"
          children="Continue"
          disabled={submitDisabled}
        />
      </Box>
    </Box>
  );
}
