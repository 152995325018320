import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Grid,
  Typography,
  Button,
  Box,
  TextField,
  TextFieldProps,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";
import { useTheme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import { CardAvatar } from "../card/CardAvatar";
import { ReactComponent as ChangePasswordIcon } from "../../icons/LockClosedOutlined.svg";
import { TimezoneField } from "../fields/TimezoneField";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { terraCapacitorEnabled, useTerraSdk } from "../../utils/terra-sdk";
import { validatePhone } from "../../utils/user";
import {
  Units,
  Gender,
  SOMETHING_WENT_WRONG,
  PHONE_NUMBER_VERIFICATION_CODE_SENT,
} from "../../constants";
import { SettingsCard } from "../card/SettingsCard";
import { VerifyPhoneDialog } from "../dialog/VerifyPhoneDialog";
import { UploadAvatarProps, UploadAvatar } from "../fields/UploadAvatar";
import { BirthdayFieldProps, BirthdayField } from "../fields/BirthdayField";
import { FieldsGroup } from "../fields/FieldsGroup";
import {
  BodyWeightHeightValue,
  BodyWeightHeightFieldProps,
  BodyWeightHeightField,
} from "../fields/BodyWeightHeightField";
import { FieldLabel } from "../fields/FieldLabel";
import { PhoneField, PhoneFieldProps } from "../fields/PhoneField";
import { GenderField } from "../fields/GenderField";
import { DiscardChangesDialog } from "../dialog/DiscardChangesDialog";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useLogout } from "../../hooks/useLogout";
import { ReactComponent as TrashIcon } from "../../icons/trash.svg";

import { DeleteUserDialog } from "./dialogs/DeleteUserDialog";
import { ChangeEmailDialog } from "./dialogs/ChangeEmailDialog";
import { ChangePasswordDialog } from "./dialogs/ChangePasswordDialog";
import PreLoader from "../loading/PreLoader";
import TerraConnectButton from "./TerraConnectButton";

import { ClientSettingsProfileVerifyPhoneMutation } from "./__generated__/ClientSettingsProfileVerifyPhoneMutation.graphql";
import { ClientSettingsProfileUpdateMutation } from "./__generated__/ClientSettingsProfileUpdateMutation.graphql";
import { ClientSettingsProfile_user$key } from "./__generated__/ClientSettingsProfile_user.graphql";
import { colorSystem } from "../../theme";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,

    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0 !important",
      paddingRight: "0 !important",
    },
  },

  card: {
    padding: theme.spacing(3),
  },

  subheaderText: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    textAlign: "center",

    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
      fontWeight: 500,
      textAlign: "initial",
    },
  },

  button: {
    fontSize: 16,
    fontWeight: "bold",
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1, 3),

    "&.Mui-disabled": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.text.secondary,
    },

    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1.75, 3),
    },
  },

  phoneField: {
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(3),
    },
  },

  verifyButtonBox: {
    height: "100%",
  },

  verifyButton: {
    height: theme.spacing(7),
    minWidth: "50%",
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    width: "100%",
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(4.25),
      width: "auto",
    },
  },

  photoUrl: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",

    [theme.breakpoints.down("sm")]: {
      "& > div:nth-child(2)": {
        flexGrow: 1,
      },
    },
  },

  avatar: {
    borderRadius: theme.spacing(0.5),
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginRight: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      borderRadius: theme.spacing(1),
      marginRight: theme.spacing(4),
      width: theme.spacing(13),
      height: theme.spacing(13),
    },
  },

  fields: {
    display: "grid",

    margin: theme.spacing(3, 0, 0, 0),
    alignItems: "start",

    gridGap: 0,
    gridTemplateColumns: "1fr",

    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(5, 0, 0, 0),
      gridGap: theme.spacing(3),
      gridTemplateColumns: "1fr 1fr",
    },
  },

  passwordField: {
    gridColumn: 1,

    [theme.breakpoints.down("sm")]: {
      "& > h4": {
        marginBottom: theme.spacing(-1),
      },

      "& > div": {
        display: "none",
      },
    },
  },

  emailField: {
    [theme.breakpoints.up("sm")]: {
      position: "relative",

      "& > button": {
        position: "absolute",
        left: 0,
        bottom: theme.spacing(-5),
      },
    },
  },

  changeEmailButton: {
    marginBottom: theme.spacing(-2.5),
  },

  changePasswordButton: {
    [theme.breakpoints.up("sm")]: {
      color: theme.palette.primary.main,
      padding: theme.spacing(0.5, 1),
      marginLeft: theme.spacing(-1),
      marginTop: theme.spacing(4),
    },

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: theme.spacing(1.25),
      marginBottom: theme.spacing(0.5),
      fontWeight: 700,
      fontSize: 14,

      backgroundColor: theme.palette.selected.light,

      borderRadius: theme.spacing(0.5),
      borderColor: theme.palette.secondary.main,
      borderWidth: 1,
      borderStyle: "solid",
    },

    "& svg": {
      marginRight: theme.spacing(1),
    },
  },

  terraConnectButton: {
    marginBottom: theme.spacing(2),
  },

  dialog: {
    "& .MuiDialog-paperWidthSm": {
      [theme.breakpoints.up("sm")]: {
        minWidth: 600,
      },
    },
  },

  radioLabel: {
    fontWeight: 500,
  },

  deleteAccount: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Montserrat, sans-serif",
    fontSize: 18,
    letterSpacing: "0.01em",
    fontWeight: 600,
    color: colorSystem.primary,

    [theme.breakpoints.down("md")]: {
      margin: "0 auto",
    },

    "& span": {
      marginLeft: theme.spacing(1),
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  terraContainer: {
    gap: theme.spacing(2),
  },
}));

const fragment = graphql`
  fragment ClientSettingsProfile_user on User {
    id
    isSample
    displayName
    email
    photoURL
    phone
    phoneCode
    phoneVerified
    passwordUpdatedAt(format: "MMM DD, YYYY")
    location
    birthday
    gender
    timeZone
    weight
    height
    units
    terraConnected
    terraProviders
    terraSdkOptions {
      devId
      referenceId
      token
    }
  }
`;

const updateUserMutation = graphql`
  mutation ClientSettingsProfileUpdateMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...CurrentUserProvider_user
        ...ClientSettingsProfile_user
      }
    }
  }
`;

export const verifyPhoneMutation = graphql`
  mutation ClientSettingsProfileVerifyPhoneMutation($input: VerifyPhoneInput!) {
    verifyPhone(input: $input) {
      sid
    }
  }
`;

export const hardDeleteClientMutation = graphql`
  mutation ClientSettingsProfileHardDeleteMutation(
    $input: HardDeleteClientInput!
  ) {
    hardDeleteClient(input: $input) {
      deleted
    }
  }
`;

export interface ClientSettingsProfileProps
  extends Omit<ContainerProps, "children"> {
  userRef: ClientSettingsProfile_user$key;
  connectTerra?: boolean;
}

export function ClientSettingsProfile(props: ClientSettingsProfileProps) {
  const { className, userRef, connectTerra = false, ...other } = props;
  const s = useStyles();
  const { brandName } = useCurrentBrand();
  const snackAlert = useSnackAlert();
  const logout = useLogout();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const user = useFragment(fragment, userRef);
  const { isImpersonating } = useCurrentUser();
  const [passwordDialogOpen, setPasswordDialogOpen] = React.useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = React.useState(false);
  const [deleteUserDialogOpen, setDeleteUserDialogOpen] = React.useState(false);
  const [dirty, setDirty] = React.useState(false);
  const [phoneCode, setPhoneCode] = React.useState<string>(user.phoneCode);
  const [verifyPhoneDialogOpen, setVerifyPhoneDialogOpen] =
    React.useState(false);
  const [displayName, setDisplayName] = React.useState(user.displayName);
  const [phone, setPhone] = React.useState(user.phone || "");
  const [photoURL, setPhotoURL] = React.useState(user.photoURL);
  const [heightWeight, setHeightWeight] = React.useState<BodyWeightHeightValue>(
    {
      units: user.units as Units,
      height: user.height,
      weight: user.weight,
    },
  );
  const [location, setLocation] = React.useState(user.location);
  const [birthday, setBirthday] = React.useState(user.birthday);
  const [gender, setGender] = React.useState(user.gender);
  const [timeZone, setTimeZone] = React.useState(user.timeZone);
  const { connected, init: initTerraSdk } = useTerraSdk(user.terraSdkOptions);

  const [updateUser, updateUserInFlight] =
    useMutation<ClientSettingsProfileUpdateMutation>(updateUserMutation);

  const [verifyPhone, verifyPhoneInFlight] =
    useMutation<ClientSettingsProfileVerifyPhoneMutation>(verifyPhoneMutation);

  const [deleteUser, deleteUserInFlight] = useMutation(
    hardDeleteClientMutation,
  );

  const handleError = React.useCallback(
    (message: string) => {
      snackAlert({
        severity: "error",
        message,
      });
    },
    [snackAlert],
  );

  const handleChangePassword = React.useCallback(() => {
    setPasswordDialogOpen(true);
  }, []);

  const handlePasswordDialogClose = React.useCallback(() => {
    setPasswordDialogOpen(false);
  }, []);

  const handleOpenEmailDialog = React.useCallback(() => {
    setEmailDialogOpen(true);
  }, []);

  const handleCloseEmailDialog = React.useCallback(() => {
    setEmailDialogOpen(false);
  }, []);

  const handleCloseVerifyPhoneDialog = React.useCallback(() => {
    setVerifyPhoneDialogOpen(false);
  }, []);

  const handleDisplayNameChange: TextFieldProps["onChange"] = React.useCallback(
    (event) => {
      setDisplayName(event.target.value);
      setDirty(true);
    },
    [],
  );

  const handleChangePhotoURL: UploadAvatarProps["onChange"] = React.useCallback(
    (value) => {
      setPhotoURL(value);
      setDirty(true);
    },
    [],
  );

  const handlePhoneChange: PhoneFieldProps["onChange"] = React.useCallback(
    (event, value, countryCode) => {
      setPhone(value);
      setPhoneCode(countryCode);
      setDirty(true);
    },
    [],
  );

  const handleDeleteUser = React.useCallback(() => {
    setDeleteUserDialogOpen(false);
    deleteUser({
      variables: { input: {} },
      onCompleted(data, errors) {
        if (errors) {
          snackAlert({
            severity: "error",
            message: errors[0].message || SOMETHING_WENT_WRONG,
          });
        } else {
          logout();
        }
      },
      onError(err) {
        snackAlert({
          severity: "error",
          message: SOMETHING_WENT_WRONG,
        });
        console.error(err.message || SOMETHING_WENT_WRONG);
      },
    });
  }, [deleteUser, snackAlert, logout, setDeleteUserDialogOpen]);

  const handleVerifyPhone = React.useCallback(() => {
    verifyPhone({
      variables: { input: { phone } },
      onCompleted(data, errors) {
        if (errors) {
          snackAlert({
            severity: "error",
            message: errors[0].message || SOMETHING_WENT_WRONG,
          });
        } else {
          setVerifyPhoneDialogOpen(true);
          snackAlert({
            severity: "info",
            message: PHONE_NUMBER_VERIFICATION_CODE_SENT,
          });
        }
      },
      onError(err) {
        snackAlert({
          severity: "error",
          message: SOMETHING_WENT_WRONG,
        });
        console.error(err.message || SOMETHING_WENT_WRONG);
      },
    });
  }, [phone, snackAlert, verifyPhone]);

  const handleChangeHeightWeight: BodyWeightHeightFieldProps["onChange"] =
    React.useCallback((value) => {
      setHeightWeight(value);
      setDirty(true);
    }, []);

  const handleLocationChange: TextFieldProps["onChange"] = React.useCallback(
    (event) => {
      setLocation(event.target.value);
      setDirty(true);
    },
    [],
  );

  const handleBirthdayChange: BirthdayFieldProps["onChange"] =
    React.useCallback((value) => {
      setBirthday(value);
      setDirty(true);
    }, []);

  const handleGenderChange = React.useCallback((value: Gender) => {
    setGender(value);
    setDirty(true);
  }, []);

  const handleSave = React.useCallback(() => {
    const promises = [];

    if (dirty) {
      promises.push(
        new Promise((resolve, reject) =>
          updateUser({
            variables: {
              input: {
                id: user.id,
                displayName,
                photoURL,
                phone,
                phoneCode,
                ...heightWeight,
                location,
                birthday,
                gender,
                timeZone,
              },
            },

            onCompleted(response, errors) {
              if (errors) {
                reject(errors);
              } else {
                setDirty(false);
                resolve(response);
              }
            },
          }),
        ),
      );
    }

    Promise.all(promises)
      .then(() => {
        if (promises.length) {
          snackAlert({
            severity: "success",
            message: "Settings updated.",
          });
        }
      })
      .catch(() =>
        snackAlert({
          severity: "error",
          message: "Failed to update settings.",
        }),
      );
  }, [
    birthday,
    dirty,
    displayName,
    gender,
    heightWeight,
    location,
    phone,
    phoneCode,
    photoURL,
    snackAlert,
    timeZone,
    updateUser,
    user.id,
  ]);

  const displayNameIsValid = Boolean(displayName);
  const phoneIsValid = !phone || phone.length <= 5 || validatePhone(phone);
  const heightIsValid = Boolean(heightWeight.height);
  const weightIsValid = Boolean(heightWeight.weight);
  const unitsIsValid = Boolean(heightWeight.units);
  const locationIsValid = Boolean(location);
  const birthdayIsValid = Boolean(birthday);
  const genderIsValid = Boolean(gender);
  const timeZoneIsValid = Boolean(timeZone);
  const inFlight = updateUserInFlight || verifyPhoneInFlight;
  const phoneVerified = user.phoneVerified && user.phone === phone;
  const verifyDisabled = inFlight || !validatePhone(phone) || phoneVerified;
  const canSave =
    dirty &&
    displayNameIsValid &&
    phoneIsValid &&
    heightIsValid &&
    weightIsValid &&
    unitsIsValid &&
    locationIsValid &&
    birthdayIsValid &&
    genderIsValid &&
    timeZoneIsValid;

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <DiscardChangesDialog dirty={dirty} />
      <SettingsCard
        header="Profile"
        ButtonProps={{
          disabled: inFlight || !canSave,
          onClick: handleSave,
        }}
      >
        <FieldLabel>Profile image</FieldLabel>
        <Box className={s.photoUrl}>
          <CardAvatar className={s.avatar} src={photoURL} />

          <UploadAvatar
            onChange={handleChangePhotoURL}
            value={photoURL}
            onError={handleError}
            disabled={inFlight}
            uploadOnly
          >
            <Button
              variant="contained"
              className={s.button}
              disabled={inFlight}
              fullWidth
            >
              Upload new picture
            </Button>
          </UploadAvatar>
        </Box>

        <Box className={s.fields}>
          <FieldsGroup label="Full name">
            <TextField
              variant="outlined"
              fullWidth
              value={displayName}
              onChange={handleDisplayNameChange}
              error={!displayNameIsValid}
              disabled={inFlight}
            />
          </FieldsGroup>

          <FieldsGroup className={s.emailField} label="Email address">
            <TextField
              variant="outlined"
              fullWidth
              type="helperText"
              value={user.email}
              disabled
            />
            <Button
              className={s.changeEmailButton}
              variant="text"
              color="primary"
              onClick={handleOpenEmailDialog}
            >
              Change email address
            </Button>
          </FieldsGroup>

          <PhoneField
            className={s.phoneField}
            value={phone}
            countryCode={phoneCode}
            onChange={handlePhoneChange}
            error={!phoneIsValid && "Incorrect phone number"}
            disabled={inFlight}
          />

          <Box className={s.verifyButtonBox}>
            <Button
              className={s.verifyButton}
              variant="contained"
              onClick={handleVerifyPhone}
              disabled={verifyDisabled}
              children={phoneVerified ? "Verified" : "Verify"}
            />
          </Box>

          <FieldsGroup label="Password">
            <TextField
              variant="outlined"
              type="password"
              value="••••••••••••"
              fullWidth
              disabled={inFlight}
            />
          </FieldsGroup>

          <Box>
            <Button
              variant="text"
              onClick={handleChangePassword}
              className={s.changePasswordButton}
              disabled={inFlight}
            >
              <ChangePasswordIcon />
              Change password
            </Button>

            <Typography variant="body1" className={s.subheaderText}>
              Last changed {user.passwordUpdatedAt}
            </Typography>
          </Box>

          <BodyWeightHeightField
            onChange={handleChangeHeightWeight}
            value={heightWeight}
            disabled={inFlight}
          />

          <FieldsGroup label="Location">
            <TextField
              variant="outlined"
              fullWidth
              value={location}
              onChange={handleLocationChange}
              error={!locationIsValid}
              disabled={inFlight}
            />
          </FieldsGroup>

          <BirthdayField
            onChange={handleBirthdayChange}
            value={birthday}
            disabled={inFlight}
            valid={birthdayIsValid}
          />

          <TimezoneField
            value={timeZone}
            onChange={setTimeZone}
            disabled={inFlight}
            error={!timeZoneIsValid}
          />

          <GenderField value={gender as Gender} onChange={handleGenderChange} />

          <FieldsGroup label="Connect your nutrition or activty tracker">
            {!isImpersonating && (
              <Grid container className={s.terraContainer}>
                {terraCapacitorEnabled && (
                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    disabled={connected}
                    onClick={() => {
                      void initTerraSdk();
                    }}
                  >
                    {connected
                      ? "Apple Health Authorized"
                      : "Authorize Apple Health"}
                  </Button>
                )}
                <TerraConnectButton
                  className={s.terraConnectButton}
                  connected={user.terraConnected}
                />
              </Grid>
            )}
            {!!user.terraConnected && (
              <Typography variant="caption">
                Connected services:{" "}
                {user.terraProviders
                  .map((provider) => provider.toLowerCase())
                  .join(", ")}
              </Typography>
            )}
          </FieldsGroup>
        </Box>
        {!user.isSample && (
          <IconButton
            className={s.deleteAccount}
            color="secondary"
            onClick={() => setDeleteUserDialogOpen(true)}
            size="large"
          >
            <TrashIcon />
            <span>Delete account</span>
          </IconButton>
        )}
      </SettingsCard>

      {emailDialogOpen && (
        <ChangeEmailDialog
          className={s.dialog}
          id={user.id}
          email={user.email}
          open={true}
          onClose={handleCloseEmailDialog}
          fullScreen={!mdUp}
        />
      )}

      {deleteUserDialogOpen && (
        <DeleteUserDialog
          onClose={() => setDeleteUserDialogOpen(false)}
          onDelete={handleDeleteUser}
        />
      )}

      {deleteUserInFlight && (
        <PreLoader
          text={`We are currently deleting out your ${brandName} account and data`}
        />
      )}

      {passwordDialogOpen && (
        <ChangePasswordDialog
          id={user.id}
          open={true}
          onClose={handlePasswordDialogClose}
          fullScreen={!mdUp}
        />
      )}

      {verifyPhoneDialogOpen && (
        <VerifyPhoneDialog
          open={true}
          onClose={handleCloseVerifyPhoneDialog}
          phone={phone}
        />
      )}
    </Container>
  );
}
