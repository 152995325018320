import clsx from "clsx";
import React, { FormEventHandler } from "react";
import { Box, BoxProps, Typography, TextField, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { deviceSupports } from "../../utils/device";
import { AuthButtonProps } from "../../utils/auth";

import { AuthButton } from "./AuthButton";
import { OrDivider } from "./OrDivider";
import { EmailNoAccount } from "./EmailNoAccount";
import { GoogleButton } from "./GoogleButton";
import { AppleButton } from "./AppleButton";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 457,
    textAlign: "center",
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
  },

  emailNoAccount: {
    marginTop: theme.spacing(3),
  },

  googleButton: {
    marginTop: theme.spacing(3),
  },

  divider: {
    marginTop: theme.spacing(3),
  },

  email: {
    marginTop: theme.spacing(3),
  },

  password: {
    marginTop: theme.spacing(2),
  },

  submitButton: {
    marginTop: theme.spacing(3),
  },

  text: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
  },

  forgotPassword: {
    marginTop: theme.spacing(3),
  },
}));

export type LoginCredentials = {
  email: string;
  password: string;
};

export interface LoginFormPropsX extends BoxProps {
  credentials: LoginCredentials;
  errors: LoginCredentials;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: FormEventHandler<HTMLDivElement>;
  disabled?: boolean;
  noAccountEmail?: string;
  onSignIn?: AuthButtonProps["onSuccess"];
}

export function LoginFormX(props: LoginFormPropsX) {
  const {
    className,
    credentials,
    errors,
    onSubmit,
    onChange,
    disabled = false,
    noAccountEmail,
    onSignIn,
    ...other
  } = props;
  const s = useStyles();
  const { brandName } = useCurrentBrand();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Typography className={s.title} variant="h1">
        Log in to {brandName}
      </Typography>

      {noAccountEmail && (
        <EmailNoAccount className={s.emailNoAccount} email={noAccountEmail} />
      )}

      {(deviceSupports.googleAuthSignInEnabled ||
        deviceSupports.appleAuthSignInEnabled) && (
        <>
          {deviceSupports.appleAuthSignInEnabled && (
            <AppleButton
              onSuccess={onSignIn}
              disabled={disabled}
              children="Sign in with Apple"
            />
          )}

          {deviceSupports.googleAuthSignInEnabled && (
            <GoogleButton
              onSuccess={onSignIn}
              disabled={disabled}
              children="Sign in with Google"
            />
          )}

          <OrDivider className={s.divider} />
        </>
      )}

      <Box component={"form"} onSubmit={onSubmit}>
        <TextField
          className={s.email}
          variant="outlined"
          name="email"
          type="email"
          label="Email address"
          placeholder="Email address"
          value={credentials.email}
          onChange={onChange}
          error={Boolean(errors.email)}
          helperText={errors.email}
          disabled={disabled}
          fullWidth
        />

        <TextField
          className={s.password}
          variant="outlined"
          name="password"
          type="password"
          label="Password"
          placeholder="Password"
          value={credentials.password}
          onChange={onChange}
          error={Boolean(errors.password)}
          helperText={errors.password}
          disabled={disabled}
          fullWidth
        />

        <AuthButton
          className={s.submitButton}
          type="submit"
          children="Log in"
          disabled={disabled || !credentials.email || !credentials.password}
        />
      </Box>

      <Typography className={clsx(s.text, s.forgotPassword)}>
        <Link href="/forgot-password">Forgot password?</Link>
      </Typography>

      <Typography className={s.text}>
        Don’t have an account? <Link href="/signup">Sign Up as Coach</Link>
      </Typography>
    </Box>
  );
}
