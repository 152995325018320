import clsx from "clsx";
import React from "react";
import { Paper, Typography, Link, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useMutation, graphql } from "react-relay/hooks";

import {
  FullScreenDialog,
  FullScreenDialogProps,
} from "../dialog/FullScreenDialog";
import { AppLogo } from "../app/AppLogo";
import newsletter from "../../icons/newsletter.svg";
import { colorSystem } from "../../theme";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";

import { PasswordResetDialogMutation } from "./__generated__/PasswordResetDialogMutation.graphql";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",

    "& .MuiDialog-paper": {
      backgroundColor: colorSystem.white3,
    },

    "& .MuiContainer-root": {
      maxWidth: 674,
    },
  },

  dialog: {
    padding: theme.spacing(5),
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },

  text: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(5),
  },

  link: {
    cursor: "pointer",
  },
}));

const resetPasswordMutation = graphql`
  mutation PasswordResetDialogMutation($email: String!) {
    resetPassword(email: $email)
  }
`;

export interface PasswordResetDialogProps extends FullScreenDialogProps {
  email: string;
}

export function PasswordResetDialog(props: PasswordResetDialogProps) {
  const { className, email, ...other } = props;
  const s = useStyles();
  const { brandName } = useCurrentBrand();
  const snackAlert = useSnackAlert();
  const onError = useGenericErrorHandler();
  const [resend, resending] = useMutation<PasswordResetDialogMutation>(
    resetPasswordMutation,
  );

  const handleResendClick = React.useCallback(() => {
    if (!resending) {
      resend({
        variables: { email },
        onCompleted(_, errors) {
          if (errors && errors[0]) {
            onError(errors[0]);
          } else {
            snackAlert({
              severity: "success",
              message: "Recovery link sent.",
            });
          }
        },
        onError,
      });
    }
  }, [resending, resend, email, onError, snackAlert]);

  return (
    <FullScreenDialog
      className={clsx(s.root, className)}
      fullScreen
      showBackButton={false}
      hideAppToolbar
      {...other}
    >
      <Paper className={s.dialog}>
        <Box display={"flex"} width={"100%"} sx={{ justifyContent: "center" }}>
          <AppLogo main color />
        </Box>
        <Typography className={s.title} variant="h1">
          Your password reset email is on the way!
        </Typography>
        <Box display={"flex"} width={"100%"} sx={{ justifyContent: "center" }}>
          <img src={newsletter} alt="" width={155} />
        </Box>
        <Typography className={s.text} variant="body1">
          If you have a {brandName} account, we'll email you a a link to reset
          your password.
        </Typography>

        <Typography className={s.text} variant="body1">
          Can’t find the email? Check your spam folder,
          <br />
          or{" "}
          <Link className={s.link} onClick={handleResendClick}>
            resend the recovery link
          </Link>
          .
        </Typography>
      </Paper>
    </FullScreenDialog>
  );
}
