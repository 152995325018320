import React from "react";
import { PoweredByStridist, PoweredByStridistProps } from "./PoweredByStridist";
import {
  useCurrentBrand,
  useCurrentWorkspace,
} from "../../hooks/useCurrentWorkspace";

export interface ConsentBannerProps extends PoweredByStridistProps {}

export function ConsentBanner(props: ConsentBannerProps) {
  const { className } = props;
  const { isBrandedWorkspace } = useCurrentBrand();
  return !isBrandedWorkspace && <PoweredByStridist className={className} />;
}
