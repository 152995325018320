import React, { FormEvent } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, TextField, Button } from "@mui/material";

import { Link } from "../link/Link";
import Em from "../typography/Em";
import { AuthButtonProps } from "../../utils/auth";

import { LoginContainer, LoginButtons } from "./LoginContainer";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: theme.spacing(2),
  },
  button: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
  error: {
    paddingTop: theme.spacing(1),
  },
}));

type FormError = {
  _?: string;
  email?: string;
  password?: string;
};

type FormState = {
  email: string;
  password: string;
};
export interface LoginFormProps {
  className?: string;
  errors: FormError;
  state: FormState;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  noAccountEmail?: string;
  loading?: boolean;
  message?: string;
  onSignIn?: AuthButtonProps["onSuccess"];
}

export function LoginForm(props: LoginFormProps) {
  const s = useStyles();
  const { brandName } = useCurrentBrand();
  const { className, onSubmit, onChange, message, state, loading, errors } =
    props;

  return (
    <LoginContainer
      className={className}
      title={
        <React.Fragment>
          Welcome to <Em>{brandName}</Em>
        </React.Fragment>
      }
      subheader={message ? message : null}
    >
      <form onSubmit={onSubmit}>
        <TextField
          className={s.field}
          name="email"
          type="email"
          label="Email Address"
          placeholder="email@example.com"
          value={state.email}
          onChange={onChange}
          disabled={loading}
          error={Boolean(errors.email)}
          helperText={errors.email}
          fullWidth
        />
        <TextField
          className={s.field}
          name="password"
          type="password"
          label="Password"
          placeholder="* * * * * *"
          value={state.password}
          onChange={onChange}
          disabled={loading}
          error={Boolean(errors.password)}
          helperText={errors.password}
          fullWidth
        />
        <Typography color="textSecondary" variant="body2">
          <Link href="/login/help" state={{ email: state.email }}>
            Forgot your password?
          </Link>
        </Typography>
        <LoginButtons>
          <Button
            className={s.button}
            variant="contained"
            type="submit"
            disabled={loading}
            children="Log in"
          />
          {errors._ && (
            <Typography className={s.error} color="error" variant="body2">
              {errors._}
            </Typography>
          )}
        </LoginButtons>
        <Typography color="textSecondary" variant="body2">
          Don't have an account?{" "}
          <Link href="/signup" state={{ email: state.email }}>
            Sign up
          </Link>
        </Typography>
      </form>
    </LoginContainer>
  );
}
