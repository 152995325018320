/**
 * @generated SignedSource<<08a969923d7698b6088d44fabb55739f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InviteRouteQuery$variables = {
  code: string;
};
export type InviteRouteQuery$data = {
  readonly inviteCode: {
    readonly " $fragmentSpreads": FragmentRefs<"ClientLinkInviteScreen_inviteCode">;
  } | null | undefined;
  readonly workspace: {
    readonly " $fragmentSpreads": FragmentRefs<"ThemeProvider_workspace">;
  };
};
export type InviteRouteQuery = {
  response: InviteRouteQuery$data;
  variables: InviteRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "code"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "code",
    "variableName": "code"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "inviteCode",
    "variableName": "code"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "imageURL",
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InviteCode",
        "kind": "LinkedField",
        "name": "inviteCode",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ClientLinkInviteScreen_inviteCode"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Workspace",
        "kind": "LinkedField",
        "name": "workspace",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ThemeProvider_workspace"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InviteRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InviteCode",
        "kind": "LinkedField",
        "name": "inviteCode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "coach",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "photoURL",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": "clientsCountNoSample",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "noSample",
                    "value": true
                  }
                ],
                "kind": "ScalarField",
                "name": "clientsCount",
                "storageKey": "clientsCount(noSample:true)"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "plan",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Group",
            "kind": "LinkedField",
            "name": "group",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Program",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Workspace",
        "kind": "LinkedField",
        "name": "workspace",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "primaryColor",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logoURL",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mealLogging",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isBrandedApp",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "83f417a0a0999d573b79e5f1573e024d",
    "id": null,
    "metadata": {},
    "name": "InviteRouteQuery",
    "operationKind": "query",
    "text": "query InviteRouteQuery(\n  $code: String!\n) {\n  inviteCode(code: $code) {\n    ...ClientLinkInviteScreen_inviteCode\n    id\n  }\n  workspace(inviteCode: $code) {\n    ...ThemeProvider_workspace\n    id\n  }\n}\n\nfragment Avatar_user on User {\n  displayName\n  photoURL\n}\n\nfragment ClientDetailedLinkInviteScreen_inviteCode on InviteCode {\n  code\n  coach {\n    ...Avatar_user\n    displayName\n    id\n  }\n  group {\n    name\n    description\n    imageURL\n    id\n  }\n  program {\n    name\n    description\n    imageURL\n    id\n  }\n}\n\nfragment ClientLinkInviteScreen_inviteCode on InviteCode {\n  ...ClientDetailedLinkInviteScreen_inviteCode\n  code\n  coach {\n    displayName\n    clientsCountNoSample: clientsCount(noSample: true)\n    plan\n    id\n  }\n  group {\n    id\n  }\n  program {\n    id\n  }\n}\n\nfragment ThemeProvider_workspace on Workspace {\n  primaryColor\n  name\n  logoURL\n  message\n  slug\n  mealLogging\n  isBrandedApp\n}\n"
  }
};
})();

(node as any).hash = "0d5ac04b619d29c057b7f538ff725733";

export default node;
