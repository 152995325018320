import React from "react";
import { ICheckInQuestionPropsBase } from "../CheckInQuestions";
import { EditorElementView } from "../../utils/editorUtils";
import DisabledCheckInTooltip from "../tooltips/DisabledCheckInTooltip";
import CheckInTextfield from "./CheckInTextfield";

export type IShortTextQuestionAnswer = {
  text?: string;
};

const ShortTextQuestion = ({
  view,
  element,
  disableAnswer,
  handleSetNode,
}: ICheckInQuestionPropsBase<IShortTextQuestionAnswer>) => {
  const answer = element.answer as IShortTextQuestionAnswer;

  const handleChange = (newValue: string | null) => {
    handleSetNode({ answer: { text: newValue } }, true);
  };

  const isCoachView = view === EditorElementView.Coach;
  const isCoachReview = view === EditorElementView.Review;
  const disabled = isCoachView || isCoachReview || disableAnswer;

  return (
    <DisabledCheckInTooltip visible={isCoachView}>
      <CheckInTextfield
        value={answer?.text ?? ""}
        onChange={(e) => handleChange(e.target.value)}
        disabled={disabled}
        placeholder="Jot down your thoughts..."
        fullWidth
        sx={{
          // Intentionally overriding `pointerEvents` from parent `CheckIn` container to allow text scrolling.
          // Disable textfield with default styles to prevent from being changed.
          ...(isCoachReview && {
            "& .Mui-disabled": {
              pointerEvents: "auto",
              color: "inherit",
              fill: "inherit",
              WebkitTextFillColor: "inherit",
            },
          }),
        }}
      />
    </DisabledCheckInTooltip>
  );
};

export default ShortTextQuestion;
