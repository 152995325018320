import { Crop, PixelCrop } from "react-image-crop";
import { canvasPreview } from "./components/new-editor/elements/canvasPreview";

const loadImageAsDataUrl = async (
  url: string,
  width: number,
  height: number,
): Promise<HTMLImageElement> => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image(width, height);
      img.src = reader.result as string;
      img.onload = () => resolve(img);
      img.onerror = reject;
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const cropImageFromUrlToFile = async (
  imageElement: HTMLImageElement,
  imgUrl: string,
  pixelCrop: PixelCrop,
): Promise<File> => {
  const image = await loadImageAsDataUrl(
    imgUrl,
    imageElement.width,
    imageElement.height,
  );

  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    canvasPreview(image, canvas, pixelCrop);

    canvas.toBlob(
      (blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        resolve(new File([blob], "", { type: "image/png" }));
      },
      "image/png",
      1,
    );
  });
};
